const types = [
    { 
        value: 'outcome_line',
        label: 'Line Chart - Outcome (passed, failed, completed)',
        for: [
            'checklist'
        ],
        verbs: [
            { value: 'total', label: 'Total # of checklists performed'},
            { value: 'month', label: 'The month name at time of report creation'},
        ],
        requires_config: true 
    },
    {
        value: 'equipment_status_pie', 
        label: 'Pie Chart - Equipment Status (operational vs faulty)', 
        for: [
            'equipment'
        ],
        verbs: [
            { value: 'total', label: 'Total # of Equipemt on the report' },
            { value: 'total_faulty', label: 'Total # of Faulty Equipemt on the report' },
            { value: 'total_operational', label: 'Total # of Operational Equipemt on the report' },
            { value: 'month', label: 'The month name at time of report creation' }, 
        ],
        requires_config: false
    },
    {
        value: 'text_block', 
        label: 'Text Block', 
        for: [
            'equipment',
            'checklist'
        ],
        verbs: [
            { value: 'total', label: 'Total # of Equipemt on the report' },
            { value: 'total_faulty', label: 'Total # of Faulty Equipemt on the report' },
            { value: 'total_operational', label: 'Total # of Operational Equipemt on the report' },
            { value: 'month', label: 'The month name at time of report creation' }, 
        ],
        requires_config: false
    },
]

export default types