<template>
    <div>
        <form>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :value="widget.title" @input="updateTitle"
                        :error="$root.errors.title">
                        Title
                    </text-input>
                </column>
                <column class="is-4">
                    <select-input required classes="is-medium is-rounded" :items="availableTypes" value-key="value"
                        label-key="label" :value="widget.type" @input="updateType" :error="$root.errors.type">
                        Type
                    </select-input>
                </column>
                <column class="is-narrow">
                    <switch-input classes="is-medium is-rounded" :value="widget.show_title" @input="toggleShowTitle"
                        :error="$root.errors.show_title" description="Toggle the title visibility on printed reports">
                        Show Title
                    </switch-input>
                </column>
            </columns>
            <columns>
                <column>
                    <text-area required classes="is-medium is-rounded" :value="widget.intro" @input="updateIntro">
                        Intro/Description
                    </text-area>
                </column>
            </columns>
            <columns v-if="widget.type && activeType.requires_config">
                <column>
                    <component :is="`report-widget-builder-${widget.type}`" />
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button :working="working" @submit="updateWidget" class="is-medium is-rounded">
                        Save
                    </submit-button>
                </column>
                <column class="is-narrow">
                    <submit-button :working="deleting" @submit="deleteWidget" class="is-small is-danger is-rounded">
                        Delete
                    </submit-button>
                </column>
            </columns>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import WidgetTypes from '../../../store/models/reporting/WidgetTypes'

export default {

    data: () => ({
        working: false,
        deleting: false,
        types: WidgetTypes
    }),

    async beforeCreate() {
        await this.$store.dispatch('reportWidget/loadWidget', this.$route.params.widget)
    },

    methods: {
        ...mapMutations('reportWidget', [
            'updateTitle',
            'updateIntro',
            'updateType',
            'toggleShowTitle'
        ]),
        updateWidget() {
            this.working = true
            this.$store.dispatch('reportWidget/updateWidget', this.widget).then(() => {
                this.working = false
                this.$toast.success('Saved')
            }).catch(() => this.working = false)
        },
        async deleteWidget() {
            if(await this.$confirm({
                title: 'Delete Widget',
                message: 'Are you sure you want to delete this widget?'
            })) {
                this.deleting = true
                this.$store.dispatch('report/deleteWidget', this.widget).then(() => {
                    this.deleting = false
                    this.$toast.success('Deleted')
                    this.$router.back()
                }).catch(() => this.deleting = false)
            }
        }
    },

    computed: {
        ...mapGetters('reportWidget', [
            'widget',
        ]),
        ...mapGetters('report', [
            'report'
        ]),
        availableTypes() {
            return this.types.filter(type => type.for.includes(this.report.type))
        },
        activeType() {
            return this.widget.type ? find(this.types, type => type.value === this.widget.type) : null
        }
    }

}
</script>